<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card v-if="event && regConfig" :elevation="isEmbeddedInApp ? 0 : 2">
      <EventHeader :event="event"/>

      <v-card-title>{{$t('events.reg.page-title', { event: event.name })}}</v-card-title>
      <v-card-text>
        <vue-markdown class="markdown" :html="false" :source="regConfig.intro_msg" />
      </v-card-text>

      <v-alert v-if="!$store.getters.isLoading && result && result.status == 'ERROR'" type="error" class="mx-4">
        <p>{{ result.msg }}</p>
        <p>{{$t('events.welcome.verification-code-mismatch')}}</p>
      </v-alert>


      <v-expansion-panels v-if="event" v-model="wizardStep" accordion tile>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
            <span><strong class="ml-1">{{ $t('events.reg.step-info-title') }}</strong></span>
            <v-spacer/>
            <span v-if="stepInfoValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepInfoValid" class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="">{{ $t('events.reg.step-info-msg') }}</p>
            <v-form ref="formInfo" v-model="valid" lazy-validation>
              <v-text-field
                v-model="request.given_name"
                :label="$t('events.reg.given-name')"
                :rules="nameRules" 
                />

              <v-text-field
                v-model="request.family_name"
                :label="$t('events.reg.family-name')"
                :rules="nameRules" 
                />

              <div v-if="regConfig.select_dob" class="">
                <v-menu
                  v-model="showDobPicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formattedDob"
                      :label="$t('profile.edit.birthdate')"
                      :rules="dobRules"
                      persistent-hint
                      append-icon="fa-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker 
                    no-title 
                    ref="dobPicker"
                    v-bind:value="request.dob"
                    v-on:input="handleDobChange"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1920-01-01"
                    >
                  </v-date-picker>
                </v-menu>
              </div>
              <div v-if="regConfig.select_gender" >
                <ToggleButtonInput v-model="request.gender" label="Gender" hint=" " :items="genderOptions" :mandatory="false" :rules="genderRules" />
              </div>

              <div v-if="user" class="">
                <v-text-field
                  v-model="request.email"
                  :label="$t('events.welcome.join-enter-email')"
                  :hint="$t('account.register.email-is-required')"
                  :rules="emailRules" 
                  persistent-hint
                  />
                <v-alert type="success" outlined class="mt-8">
                  You are logged in as {{user.email}}.
                </v-alert>
              </div>
              <div v-if="!user">
                <h4 class="mt-8">Your Account</h4>
                <p>Useful to access your data and switch between our app and web platforms.</p>
                <p>
                  Already have an account? 
                  <v-btn outlined color="primary" class="ms-2" @click="isInitialLoginState=false && $refs.loginForm.open()">Login Now</v-btn>
                  <v-btn text small class="ms-2" color="primary" to="/account/passwordrecovery">{{$t('account.login.forgot-your-password')}}</v-btn>
                </p>
                <v-text-field
                  v-model="request.email"
                  :label="$t('events.welcome.join-enter-email')"
                  :hint="$t('account.register.email-is-required')"
                  :rules="emailRules" 
                  persistent-hint
                  />

                <v-text-field
                  
                  v-model="password"
                  :rules="passwordRules"
                  :label="$t('events.reg.password')"
                  required
                  :append-icon="passwordShow ? 'fal fa-eye-slash' : 'fal fa-eye'"
                  :type="passwordShow ? 'text' : 'password'"
                  @click:append="passwordShow = !passwordShow"
                  />
              </div>
              <v-alert v-if="accountMessage" type="error" outlined class="mt-8">
                {{accountMessage}}
              </v-alert>

              <v-checkbox 
                v-if="regConfig.consent_msg"
                v-model="request.consented"
                :rules="consentRules"
                hide-details
                >
                <template v-slot:label>
                  <VueMarkdown class="markdown-content">{{regConfig.consent_msg}}</VueMarkdown>
                </template>
              </v-checkbox>

            </v-form>
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn class="" :disabled="!stepInfoValid" color="primary" :loading="$store.getters.isLoading" @click="submitInfo">{{ $t('shared.next-step') }}</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-pencil</v-icon>
            <span><strong class="ml-1">{{ $t('events.reg.step-reg-option-title') }}</strong></span>
            <v-spacer/>
            <span v-if="stepRegOptionValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepRegOptionValid" class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            
            <VueMarkdown v-if="regConfig.option_msg" class="markdown-content">{{regConfig.option_msg}}</VueMarkdown>
            <p v-else>{{ $t('events.reg.step-reg-option-msg') }}</p>

            <v-form ref="formRegOption" v-model="valid" lazy-validation>
              <v-radio-group v-model="request.reg_option_id" :rules="selectOptionRules" class="pt-0 mt-2">
                  <v-radio v-for="(item, idx) in regConfig.options" :key="idx"
                    :value="item.id"
                    :label="`${item.name} - ${!item.amount || item.amount < 1 ? $t('shared.free') : `${item.currency} ${item.amount.toLocaleString(language, {minimumFractionDigits: 0, maximumFractionDigits: 2})}` }`"
                    />              
                  </v-radio-group>

              <v-checkbox v-model="termsAccepted" hide-details ref="termsCheckbox">
                <template v-slot:label>
                  <div class="d-flex" style="flex-direction:column;">
                    <i18n path="account.register.terms_privacy_v2" tag="div" class="">
                      <router-link to='privacypolicy'>{{$t('account.register.terms_privacy_privacy')}}</router-link>
                      <router-link to='termsofservice'>{{$t('account.register.terms_privacy_terms')}}</router-link>
                    </i18n>
                    <div class="text-muted">{{$t('account.register.terms_privacy_subtext')}}</div>
                  </div>
                </template>
              </v-checkbox>
              
            </v-form>
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!showTeamStep && !showDetailsStep" :disabled="!readyToSubmit" color="primary" :loading="$store.getters.isLoading" @click="ensureTermsAreApproved() && submit()">{{ $t('events.reg.review-confirm') }}</v-btn>
              <v-btn v-else :disabled="!stepRegOptionValid" color="primary" :loading="$store.getters.isLoading" @click="ensureTermsAreApproved() && submitRegOption()">{{ $t('shared.next-step') }}</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="showTeamStep">
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-users</v-icon>
            <span><strong class="ml-1">{{ $t('events.reg.step-team-title') }}</strong></span>
            <v-spacer/>
            <span v-if="stepTeamValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepTeamValid" class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="">{{ $t('events.reg.step-team-msg') }}</p>
            <v-form ref="formTeam" v-model="valid" lazy-validation>

              <div v-if="regConfig.select_team">
                <VueMarkdown v-if="regConfig.team_msg" class="markdown-content">{{regConfig.team_msg}}</VueMarkdown>
                <v-radio-group v-model="request.team_option" :rules="selectTeamRules" class="pt-0 mt-2">
                  <v-radio
                    value="JOIN"
                    :label="$t('events.welcome.join-team-join-a-team')"
                    />
                  <v-autocomplete
                    v-if="request.team_option == 'JOIN'"
                    v-model="request.group_id"
                    :items="groups"
                    :rules="joinTeamRules"
                    item-text="name"
                    item-value="id"
                    class="ml-8"
                    :label="$t('events.welcome.join-team-select')"
                    no-filter
                    />
                  <v-radio 
                    v-if="!regConfig.block_team_create"
                    value="CREATE"
                    :label="$t('events.welcome.join-team-create')"
                    />
                    <v-text-field
                      v-if="request.team_option == 'CREATE'"
                      v-model="request.team_name"
                      class="ml-8"
                      :rules="createTeamRules"
                      :label="$t('events.welcome.join-team-create-name')"
                      />

                  <v-radio 
                    value="SKIP"
                    :label="$t('events.welcome.join-team-skip')"
                    />
                </v-radio-group>
              </div>
                            
            </v-form>
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn v-if="!showDetailsStep" :disabled="!readyToSubmit" color="primary" :loading="$store.getters.isLoading" @click="submit">{{ $t('events.reg.review-confirm') }}</v-btn>
              <v-btn v-else class="" :disabled="!stepTeamValid" color="primary" :loading="$store.getters.isLoading" @click="submitTeam">{{ $t('shared.next-step') }}</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="showDetailsStep">
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-tasks</v-icon>
            <span><strong class="ml-1">{{ $t('events.reg.step-details-title')}}</strong></span>
            <v-spacer/>
            <span v-if="stepDetailsValid" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            <span v-if="!stepDetailsValid" class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon></span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <VueMarkdown v-if="regConfig.details_msg" class="markdown-content mb-4">{{regConfig.details_msg}}</VueMarkdown>
            <p v-else>{{ $t('events.reg.step-details-msg') }}</p>
            <v-form ref="formDetails" v-model="valid" lazy-validation>

              <RegFieldInput v-model="request.udf_1" :field="regConfig.udf_1_field" :eventId="event.id" />
              <RegFieldInput v-model="request.udf_2" :field="regConfig.udf_2_field" :eventId="event.id" />
              <RegFieldInput v-model="request.udf_3" :field="regConfig.udf_3_field" :eventId="event.id" />

            </v-form>
            <p v-if="!showDetailsStep">
              All good, ready to go!
            </p>
            <div class="d-flex grey lighten-3 pa-4 mb-n4 mx-n6 mt-8">
              <v-spacer />
              <v-btn class="" :disabled="!readyToSubmit" color="primary" :loading="$store.getters.isLoading" @click="submit">{{ $t('events.reg.review-confirm') }}</v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>

      <br/>
    </v-card>

    <LoginOrRegisterDialog ref="loginForm" mode="LOGIN" />
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { DateTime } from 'luxon'
import eventService from "@/services/eventService";
import groupService from "@/services/groupService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import ToggleButtonInput from '@/components/ToggleButtonInput.vue'
import EventHeader from '@/components/EventHeader.vue';
import EventJoinCard from '@/components/EventJoinCard.vue';
import RegFieldInput from '@/components/RegFieldInput.vue';
import LoginOrRegisterDialog from '@/components/LoginOrRegisterDialog.vue';
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();
import { getAuth } from "firebase/auth";
import siteData from '@/data/site.json'

export default {
  name: "EventJoin",
  components: {
    VueMarkdown,
    ToggleButtonInput,
    EventHeader,
    EventJoinCard,
    RegFieldInput,
    LoginOrRegisterDialog,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
      language: window.navigator.language,
      accountMessage: null,
      termsAccepted: false,
      password: null,
      passwordRules: [
        v => !!v || 'Password is required',
        v => v && v.length >= 6 || 'Enter a password of 6 or more characters, please'
      ],
      passwordShow: false,
      showDobPicker: false,
      request: {  
        given_name: null,
        family_name: null,
        email: null,
        gender: null, 
        dob: null, 
        consented: false, 
        reg_option_id: null,
        team_option: null, 
        group_id: null, 
        team_name: null, 
        udf_1: null,
        udf_2: null,
        udf_3: null,
        code: null,
      },
      groups: null,
      result: null,
      valid: true,
      wizardStep: 0,
      isInitialLoginState: true,
    };
  },

  async mounted() {
    await this.loadEvent(this.$route.params.id);

    EventBus.$on('login-state-change', async user => {
      await this.getLoginStatus();
    });
    await this.getLoginStatus();

  },

  methods: {
      
    async getLoginStatus() {
      if (this.user) {
        this.request.email = this.user.email;
        var request = await profileService.get(false);
        var profile = request.data;
        // Load defaults from profile
        if (profile.given_name) this.request.given_name = profile.given_name;
        if (profile.family_name) this.request.family_name = profile.family_name;
        if (profile.dob) this.request.dob = profile.dob;
        if (profile.gender) this.request.gender = profile.gender;

        this.tryLoadReg(this.$route.params.id, this.$route.query.code);

        if (this.isInitialLoginState && this.stepInfoValid) {
          // when the user is already logged in, we can skip the initial step if all required data is filled in
          this.skip();
        }
      }
    },

    async skip() {
      this.wizardStep++;
    },
    async submitInfo() {
      if (!this.$refs.formInfo.validate()) {
        return;
      }
      if (!this.user) {
        // not logged in yet, create new account
        this.isInitialLoginState = false;
        var ok = await this.$refs.loginForm.registerOrLogin(this.request.email, this.password);
        console.log('/// return', ok);
        if (!ok) {
          this.accountMessage = "Could not create a new account for the given email address. Please make sure don't already have an account on this email address. If you already have an account, please use the Password Recovery option.";
          return;
        }
        else {
          this.accountMessage = null;
        }
      }
      this.next();
    },
    async submitRegOption() {
      if (!this.$refs.formRegOption.validate()) {
        return;
      }
      this.next();
    },
    async submitTeam() {
      if (!this.$refs.formTeam.validate()) {
        return;
      }
      this.next();
    },

    async next() {
      this.wizardStep++;
    },
    async back() {
      this.wizardStep--;
    },    


    handleDobChange(e) {
      this.request.dob = e;
      this.showDobPicker = false;
    },

    ensureTermsAreApproved(event) {
      if (!this.termsAccepted) {
        if (event) event.preventDefault();
        let elem = this.$refs.termsCheckbox;
        if (elem) {
          elem.$el.classList.add('shake');
          this.$toast.error(i18n.t('account.register.terms_privacy_validation'));
          setTimeout(() => {
            let elem = this.$refs.termsCheckbox;
            if (elem) elem.$el.classList.remove('shake');
          }, 500);
        }
        return false;
      }
      return true;
    },

    async maybeGetTeamNames() {
      if (this.user && this.event && this.event.join_select_team) {
        var response = await groupService.getGroupsInEvent(this.event.id);
        this.groups = response.data.data;
      }
    },
    
    async tryLoadReg(id, code) {
      if (id && code) {
        var reg = (await eventService.getRegistrationSummary(id, code)).data;
        if (reg) {
          this.request.code = reg.v;
          if (reg.ro) this.request.reg_option_id = reg.ro;
          if (reg.udf_1) this.request.udf_1 = reg.udf_1;
          if (reg.udf_2) this.request.udf_2 = reg.udf_2;
          if (reg.udf_3) this.request.udf_3 = reg.udf_3;
          if (reg.gn) this.request.given_name = reg.gn;
          if (reg.fn) this.request.family_name = reg.fn;
          if (reg.e) this.request.email = reg.e;
          if (reg.ge) this.request.gender = reg.ge;
          if (reg.dob) this.request.dob = reg.dob;
          if (reg.gc) {
            this.request.team_option = "JOIN";
            this.request.group_id = reg.gc;
          }
          else if (reg.g) {
            this.request.team_option = "CREATE";
            this.request.team_name = reg.g;
          }
          else {
            this.request.team_option = "SKIP";
          }
        }
      }
    },

    async loadEvent(id) {
      var response = await eventService.get(id);
      this.event = response.data;
      if (this.regConfig) {
        if (this.regConfig.options && this.regConfig.options.length == 1) {
          this.request.reg_option_id = this.regConfig.options[0].id;
        }
      }
      await this.maybeGetTeamNames();
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    

    async submit() {
      if (this.$refs.formDetails && !this.$refs.formDetails.validate()) {
        return;
      }
      
      this.result = (await eventService.submitRegistration(this.event.id, this.request)).data;
      if (this.result && this.result.status == "OK") {
        this.$router.replace({ name: "eventRegConfirm", params: { id: this.event.id, code: this.result.id } });
        return;
      }
    }
  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    regConfig() {
      return this.event && this.event.reg_config;
    },
    showTeamStep() {
      return this.regConfig.select_team;
    },
    showDetailsStep() {
      return this.regConfig.udf_1_field || this.regConfig.udf_2_field || this.regConfig.udf_3_field;
    },
    readyToSubmit() {
      return this.stepInfoValid && this.stepRegOptionValid && this.stepTeamValid && this.stepDetailsValid;
    },
    stepInfoValid() {
      return this.request
          && this.request.email
          && this.request.given_name
          && this.request.family_name
          && (!this.regConfig.consent_msg || this.request.consented);
    },
    stepRegOptionValid() {
      return this.request.reg_option_id
          && this.termsAccepted;

    },
    stepTeamValid() {
      if (!this.showTeamStep) {
        return true;
      }
      return this.request.team_option
          && ((this.request.team_option == 'CREATE' && this.request.team_name) || (this.request.team_option == 'JOIN' && this.request.group_id) || this.request.team_option == 'SKIP');
    },
    stepDetailsValid() {
      return (this.regConfig.udf_1_field == null || this.request.udf_1)
          && (this.regConfig.udf_2_field == null || this.request.udf_2)
          && (this.regConfig.udf_3_field == null || this.request.udf_3);
    },

    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.$t('events.reg.title'), disabled: true },
      ];
    },
    consentRules() {
      return !this.regConfig || !this.regConfig.consent_required ? [] : [
        v => !!v || this.$t('events.welcome.consent-validation-msg'),
      ];
    },
    nameRules() {
      return [
        v => !!v || this.$t('events.reg.name-validation-msg'),
      ];
    },
    emailRules() {
      return [
        v => !!v || this.$t('account.register.email-is-required'),
        v => /.+@.+/.test(v) || this.$t('account.register.email-must-be-valid')
      ];
    },
    selectOptionRules() {
      return [
        v => !!v || this.$t('shared.required-option'),
      ];
    },
    selectTeamRules() {
      return !this.event || !this.event.join_select_team ? [] : [
        v => !!v || this.$t('shared.required-option'),
      ];
    },
    createTeamRules() {
      return !this.event || !this.event.join_select_team || this.request.team_option !== 'CREATE' ? [] : [
        v => !!v || this.$t('events.welcome.join-create-team-validation-msg'),
      ];
    },
    joinTeamRules() {
      return !this.event || !this.event.join_select_team || this.request.team_option !== 'JOIN' ? [] : [
        v => !!v || this.$t('events.welcome.join-join-team-validation-msg'),
      ];
    },
    genderRules() {
      return [
        v => /*console.log('Validating gender option', v) ||*/ (!!v && v !== '_')  || this.$t('events.welcome.gender-validation-msg'),
      ];
    },
    dobRules() {
      return [
        v => !!v || this.$t('events.reg.dob-validation-msg'),
      ];
    },
    genderOptions() {
      var options = [
        { "text": this.$t('shared.gender-male'), "type": "M" },
        { "text": this.$t('shared.gender-female'), "type": "F" },
      ];
      if (this.regConfig.gender_options === 'M_F_O' || this.regConfig.gender_options === 'M_F_O_U') {
        options.push({ "text": this.$t('shared.gender-other'), "type": "O", "title": this.$t('shared.gender-o-desc') });
      }
      if (this.regConfig.gender_options === 'M_F_O_U') {
        options.push({ "text": this.$t('shared.gender-undisclosed'), "type": "U", "title": this.$t('shared.gender-u-desc') });
      }
      return options;
    },
    formattedDob() {
      return !this.request.dob ? null : DateTime.fromISO(this.request.dob).toLocaleString(DateTime.DATE_SHORT);
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    showDobPicker (val) {
      val && setTimeout(() => (this.$refs.dobPicker.activePicker = 'YEAR'))
    },
  },  
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadEvent(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .markdown-content {
    p { margin-bottom: 0 !important;}
  }
</style>

